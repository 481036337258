import "./footer.scss";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { t } = useTranslation();

	return (
		<footer>
			<div className="footer-list">
				<div className="footer-list__title">{t("footer.contact-us")}</div>
				<ul className="footer-list__List">
					<li><a href="mailto:melanie@mlabrocante.fr">melanie@mlabrocante.fr</a></li>
					<li><a href="tel:+33617151652">+33.06.17.15.16.52</a></li>
					<li><a href="https://g.co/kgs/Fa4PGws">6 chemin du moulin, 27800 Bosrobert, FRANCE</a></li>
				</ul>
			</div>
			<div className="footer-list">
				<div className="footer-list__title">{t("footer.our-plateforms")}</div>
				<ul className="footer-list__List">
					<li><a href="/">Ebay</a></li>
					<li><a href="/">Selency</a></li>
					<li><a href="/">Instagram</a></li>
					<li><a href="/">Facebook</a></li>
				</ul>
			</div>
			<div className="footer-contact">
				<h4>{t("footer.contact-button")}</h4>
				<a href="/contactus">{t("footer.contact-us")}</a> 
			</div>
		</footer>
	)
}