import "./legal.scss"

export default function Legal() {
    return (
        <>
            <h1 className="legal-title">Mentions légales du site</h1>
			<div className="legal-grid">
				<div class="legal-grid__element-justify-start">
					<h1>Politique de confidentialité</h1>
					<div >
						<strong>INFORMATIONS PERSONNELLES RECUEILLIES</strong>
						<br />
						Lorsque vous vous rendez sur le Site, nous recueillons automatiquement certaines informations concernant votre appareil, notamment des cookies qui sont installés sur votre appareil.
						Nous recueillons les Informations sur l'appareil à l'aide des technologies suivantes :
						<br />
						<strong>FICHIERS TÉMOINS (COOKIES)</strong>
						<br />
						Voici une liste de fichiers témoins que nous utilisons. Nous les avons énumérés ici.
						<br />
						mlb_token, identificateur unique de session, permet au de stocker les informations relatives à votre session, notamment votre email et mot de passe de manière cryptée.
						<br />
						<strong>COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES ?</strong>
						<br />
						Vos identifiants et certaines informations comme votre panier sont stockées dans notre base de donnée. Ces informations ne sont en aucun cas partagées et votre mot de passe est encrypté.
						<br />
						<strong>APPLICATIONS TIERCES </strong>
						<br />
						La fonctionnalité d'achât sur le site est assurée par STRIPE qui est une plateforme de paiement. Voir <a href="https://stripe.com/fr-be">ici</a> pour plus d'informations sur STRIPE.
						<br />
						<strong>VOS DROITS</strong>
						<br />
						Si vous êtes résident(e) européen(ne), vous disposez d'un droit d'accès aux informations personnelles que nous détenons à votre sujet et vouspouvez demander à ce qu'elles soient corrigées, mises à jour ou supprimées. Si vous souhaitez exercer ce droit, veuillez nous contacter au
						06.16.17.15.52.
						<br />
						<strong>RÉTENTION DES DONNÉES</strong>
						<br />
						Lorsque vous créer un compte, vos identifiants restent sur le site jusqu'à la suppression de votre compte.
					</div>
				</div>
				<div class="legal-grid__element-justify-start">
					<h1>Conditions générales de vente</h1>
					<div >
						<h2>Préambule</h2>
						<br />
						Les présentes conditions générales de vente (ci-après les "CGV") régissent l'ensemble des relations entre Melanie Bouteiller, immatriculée au Registre du Commerce et des Sociétés de Evreux sous le numéro 80372032500031, dont le siège social est situé à Criquebeuf-sur-Seine, France (ci-après "M'LaBrocante"), et toute personne (ci-après le "Client") effectuant un achat sur le site internet www.mlabrocante.fr (ci-après le "Site").
						<h2>Article 1 : Objet</h2>
						<br />
						Les présentes CGV ont pour objet de définir les conditions dans lesquelles M'LaBrocante propose la vente d'objets d'occasion ou neufs via son Site. Toute commande sur le Site implique l'acceptation sans réserve par le Client des présentes CGV.
						<h2>Article 2 : Commande</h2>
						<br />
						La commande est validée dès réception du paiement par M'LaBrocante. Un email de confirmation sera envoyé au Client. M'LaBrocante se réserve le droit de refuser toute commande pour des motifs légitimes.
						<h2>Article 3 : Prix</h2>
						<br />
						Les prix des produits sont indiqués en euros, toutes taxes comprises (TTC), hors frais de livraison. M'LaBrocante se réserve le droit de modifier les prix à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au moment de la validation de la commande.                
						mlb_token, identificateur unique de session, permet au de stocker les informations relatives à votre session, notamment votre email et mot de passe de manière cryptée.
						<h2>Article 4 : Paiement</h2>
						<br />
						Le paiement s'effectue par carte bancaire via Stripe, un service sécurisé de paiement en ligne. Les informations de paiement ne sont ni collectées ni conservées par M'LaBrocante.                
						Vos identifiants et certaines informations comme votre panier sont stockées dans notre base de donnée. Ces informations ne sont en aucun cas partagées et votre mot de passe est encrypté.
						<h2>Article 5 : Livraison</h2>
						<br />
						Les livraisons sont effectuées uniquement en Europe par La Poste. Les frais de livraison sont calculés en fonction des catégories de poids des articles commandés et sont indiqués lors de la validation de la commande.
						<h2>Article 6 : Droit de rétractation</h2>
						<br />
						Conformément à l'article L. 221-18 du Code de la consommation, le Client dispose d'un délai de 14 jours à compter de la réception de sa commande pour exercer son droit de rétractation, sans avoir à justifier de motifs ni à payer de pénalités, à l'exception des frais de retour.
						<h2>Article 7 : Retour des produits</h2>
						<br />
						Pour exercer son droit de rétractation, le Client doit notifier sa décision par email à melanie@mlabrocante.fr. Les produits doivent être retournés dans leur état d'origine et complets (emballage, accessoires, notice...) permettant leur recommercialisation à l’état neuf, à l’adresse suivante : [Adresse de retour]. Les frais de retour sont à la charge du Client.
						<h2>Article 8 : Responsabilité</h2>
						<br />
						M'LaBrocante ne saurait être tenue pour responsable des dommages résultant d'une mauvaise utilisation des produits achetés. La responsabilité de M'LaBrocante ne pourra être engagée en cas de retard dû à une rupture de stock chez transporteur.
						<h2>Article 9 : Garantie</h2>
						<br />
						Les produits vendus sur le Site bénéficient de la garantie légale de conformité et de la garantie contre les vices cachés, permettant au Client de retourner les produits livrés défectueux ou non conformes.
						<h2>Article 10 : Droit applicable et juridiction compétente</h2>
						<br />
						Les présentes CGV sont soumises à la loi française. En cas de litige, et après une tentative de recherche de solution amiable, compétence est attribuée aux tribunaux compétents de [Ville], nonobstant pluralité de défendeurs ou appel en garantie.
						<h2>Contact</h2>
						<br />
						Pour toute question ou réclamation, le client peut contacter M'LaBrocante à l'adresse suivante : <strong>melanie@mlabrocante.fr</strong>.
					</div>
				</div>
				<div class="legal-grid__element-justify-start">
					<div >
						<h2>Informations sur la société</h2>
						<p>Nom de la société : <strong>Melanie Bouteiller</strong></p>
						<p>Numéro de SIRET : <strong>80372032500031</strong></p>
						<p>Numéro de TVA intracommunaire : <strong>Exonération de TVA - Franchise en base</strong></p>
						<p>Nom du responsable : <strong>Melanie Bouteiller</strong></p>
						<p>Adresse du siège social de la société : 347 rue de 4 âges - - 27340 Criquebeuf-sur-Seine</p>
						<p>Editeur, directeur, responsable de rédaction : Melanie Bouteiller</p>
						<p>Hebergeur : OVH, 2, rue Kellermann, 59100 Roubaix</p>
					</div>
				</div>
			</div> 
        </>
    )
}